import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Home.css";
import APILogo from "../assets/img/API.png";
import ICPLogo from "../assets/img/ICP.jpg";
import PRFLogo from "../assets/img/PRF.jpg";
import JAPILogo from "../assets/img/JAPI.png";

import API from "../assets/img/ClickAPI.png";
import ICP from '../assets/img/ClickICP.png'
import JAPI from '../assets/img/ClickJAPI.png'
import PRF from '../assets/img/ClickPRF.png'
const Home = () => {
  return (
    <>
      {/* <!-------------Slider-section-start---------------> */}
      <section>
        <Row style={{margin: 0, marginTop:"30px"}}>
          <Col>
            <div className="logo-div">
              <img src={APILogo} alt='logo' className="logo-img"></img>
              <p className="logo-text">Association of Physicians of Indian</p>
              <a target="_blank" href="https://api.knowledgebridge.net/">
                <img src={API} alt='logo' className="btn-img"></img>
              </a>
            </div>
          </Col>
          <Col>
            <div className="logo-div">
              <img src={ICPLogo} alt='logo' className="logo-img"></img>
              <p className="logo-text">Indian College of Physicians</p>
              <a target="_blank" href="https://indiancollegeofphysicians.org/">
                <img src={ICP} alt='logo' className="btn-img"></img>
              </a>
            </div>
          </Col>
        </Row>
        <Row style={{margin: 0}}>
          <Col>
            <div className="logo-div">
              <img src={PRFLogo} alt='logo' className="logo-img"></img>
              <p className="logo-text">Physicians Research Foundation</p>
              <a target="_blank" href="#">
                <img src={PRF} alt='logo' className="btn-img"></img>
              </a>
            </div>
          </Col>
          <Col>
            <div className="logo-div">
              <img src={JAPILogo} alt='logo' className="japi"></img>
              <p className="logo-text">Journal of the Association of <br/>Physicians of India</p>
              <a target="_blank" href="https://www.japi.org/">
                <img src={JAPI} alt='logo' className="btn-img"></img>
              </a>
            </div>
          </Col>
        </Row>
        {/* <div className="container-form">
          <div className="apply_box">
            
            
            
            
          </div>
        </div> */}
      </section>
      {/* <!-------------Slider-section-End-----------------> */}
    </>
  );
};

export default Home;
