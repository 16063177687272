import React from 'react'
import "./App.css"
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ScrollToTop from './scrollToTop';
import Home from './HomePage/Home';
import PublicLayout from './layouts/PublicLayout';

const App = () => {
  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )
  return (
    <BrowserRouter basename={'/'}>
    <ScrollToTop />
    <React.Suspense fallback={loading}>
      <Routes>
        <Route exact path={'/'} element={PublicLayout(<Home />)} />
      </Routes>
    </React.Suspense>
  </BrowserRouter>
  )
}

export default App
