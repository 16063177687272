
import React from "react";


const PublicLayout = (component) => {
  return (
    <>
      {component}
    </>

  );
}

export default PublicLayout;
